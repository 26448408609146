/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Button, Title, Slideshow, Subtitle, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Studio Petra Nováková"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pl--40 pr--40" style={{"marginTop":0,"paddingBottom":0}} name={"9jcd69f9pme"}>
          
          <ColumnWrap className="column__flex --center el--2 pl--40 pr--40 flex--top" style={{"paddingBottom":28}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="pl--40" style={{"paddingBottom":0}}>
              
              <Image style={{"maxWidth":242,"marginTop":64,"marginBottom":0}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=2000x_.jpeg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=860x_.jpeg"}>
              </Image>

              <Text className="text-box fs--16" style={{"marginTop":0}} content={"<span style=\"color: var(--white);\">Kadeřnický salon v centru Kutné Hory.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pr--40" style={{"paddingBottom":0,"marginBottom":0}}>
              
              <Image style={{"maxWidth":484,"marginBottom":0,"paddingBottom":0}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/36990/947d490533aa40919f65cf1776fb359d_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36990/947d490533aa40919f65cf1776fb359d_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36990/947d490533aa40919f65cf1776fb359d_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36990/947d490533aa40919f65cf1776fb359d_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/36990/947d490533aa40919f65cf1776fb359d_s=2000x_.jpeg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/36990/947d490533aa40919f65cf1776fb359d_s=860x_.jpeg"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape5 btn-box--design3 btn-box--shadow3" style={{"backgroundColor":"var(--color-custom-1)","maxWidth":1000,"marginBottom":0,"marginTop":23,"paddingTop":0}} content={"Virtuální prohlídka salonu"} url={"https://maps.app.goo.gl/m9EYBJaj4ctvWmfe7"} href={"https://maps.app.goo.gl/m9EYBJaj4ctvWmfe7"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pl--40 pr--40" style={{"paddingBottom":0,"marginTop":0,"paddingTop":0}} name={"6a8jmvtom1y"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box title-box--center fs--30" content={"Produkty Kerastase v prodeji"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pl--40 pr--40" layout={"l8"} style={{"paddingTop":0,"marginBottom":0,"paddingBottom":14}} name={"3j14eq5jtjb"}>
          
          <ColumnWrap className="column__flex --center el--3 pl--30 pr--30" columns={"3"}>
            
            <ColumnWrapper className="pl--40">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36990/afc3ffce1e6248a4be33562395fdf916_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/36990/afc3ffce1e6248a4be33562395fdf916_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/afc3ffce1e6248a4be33562395fdf916_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/afc3ffce1e6248a4be33562395fdf916_s=860x_.jpg 860w"} style={{"maxWidth":257}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36990/2dbdb1c75a5b4686854cf3cf15678648_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/36990/2dbdb1c75a5b4686854cf3cf15678648_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/2dbdb1c75a5b4686854cf3cf15678648_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/2dbdb1c75a5b4686854cf3cf15678648_s=860x_.jpg 860w"} style={{"maxWidth":258}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pr--40">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/36990/4eafe47c55e944efa4c17c0e5e8c415a_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/36990/4eafe47c55e944efa4c17c0e5e8c415a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/4eafe47c55e944efa4c17c0e5e8c415a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/4eafe47c55e944efa4c17c0e5e8c415a_s=860x_.jpg 860w"} style={{"maxWidth":258}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pl--40 pr--40" style={{"paddingBottom":0,"marginTop":0,"paddingTop":0}} name={"pvkoym2m1wb"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"color: var(--white);\">&nbsp;Kdo se o Vás postará</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pl--40 pr--40" style={{"marginBottom":12}} name={"uvod-1"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":"","paddingLeft":26,"paddingRight":24,"paddingTop":15}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":500,"paddingLeft":0}}>
              
              <Image style={{"maxWidth":363}} alt={""} src={"https://cdn.swbpg.com/t/36990/b538d91b590d4de7a5b5f71f2b5f6831_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/36990/b538d91b590d4de7a5b5f71f2b5f6831_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/b538d91b590d4de7a5b5f71f2b5f6831_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/b538d91b590d4de7a5b5f71f2b5f6831_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":500,"paddingRight":0}}>
              
              <Text className="text-box" style={{"maxWidth":"","paddingBottom":0}} content={"Dobrý den. Mé jméno je Petra Nováková. V oboru kadeřnice působím již mnoho let. Po dobu budování své kariéry jsem působila v několika salonech ve Východních Čechách, kde jsem postupně získávala praxi. Zaměřuji se na sledování aktuálních trendů v oblasti správně zvolené techniky barvení, zesvětlování vlasů a preciznosti střihu. Přijďte si užít příjemnou atmosféru salonu a péči o vaše vlasy. Vaše spokojenost je mým cílem."}>
              </Text>

              <Image style={{"maxWidth":512,"paddingTop":0,"marginTop":0}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=2000x_.jpg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pl--40 pr--40" style={{"paddingBottom":0,"marginTop":0,"paddingTop":0}} name={"pvkoym2m1wb"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"color: var(--white);\">&nbsp;Slideshow</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Slideshow style={{"marginTop":0,"marginBottom":0,"paddingTop":0}} name={"2e60cv0392a"} autoplay={true} infinite={true} effect={"slide"} transitionDuration={5}>
          
          <Column className="pl--40 pr--40" style={{"paddingBottom":0,"paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/c8cd8eb4c7624a0995e6e45b35346afb_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36990/c8cd8eb4c7624a0995e6e45b35346afb_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36990/c8cd8eb4c7624a0995e6e45b35346afb_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36990/c8cd8eb4c7624a0995e6e45b35346afb_s=1400x_.jpeg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/c8cd8eb4c7624a0995e6e45b35346afb_s=860x_.jpeg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column >
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/1e8568c803b840d4b0957bfa04ee7d5a_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36990/1e8568c803b840d4b0957bfa04ee7d5a_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36990/1e8568c803b840d4b0957bfa04ee7d5a_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36990/1e8568c803b840d4b0957bfa04ee7d5a_s=1400x_.jpeg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/1e8568c803b840d4b0957bfa04ee7d5a_s=860x_.jpeg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column >
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/2ca991fcb44748eeb0bdc599a166c4a9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/2ca991fcb44748eeb0bdc599a166c4a9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/2ca991fcb44748eeb0bdc599a166c4a9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36990/2ca991fcb44748eeb0bdc599a166c4a9_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/2ca991fcb44748eeb0bdc599a166c4a9_s=860x_.jpg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column >
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/d2011ba63d8d4ae2bc9967e606f158a0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/d2011ba63d8d4ae2bc9967e606f158a0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/d2011ba63d8d4ae2bc9967e606f158a0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36990/d2011ba63d8d4ae2bc9967e606f158a0_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/d2011ba63d8d4ae2bc9967e606f158a0_s=860x_.jpg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column >
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/fcb4dd48e4c0456d8159037b796694a8_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36990/fcb4dd48e4c0456d8159037b796694a8_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36990/fcb4dd48e4c0456d8159037b796694a8_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36990/fcb4dd48e4c0456d8159037b796694a8_s=1400x_.jpeg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/fcb4dd48e4c0456d8159037b796694a8_s=860x_.jpeg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column >
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":5}}>
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/eeb840595ea141f98c52c661688def63_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36990/eeb840595ea141f98c52c661688def63_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36990/eeb840595ea141f98c52c661688def63_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36990/eeb840595ea141f98c52c661688def63_s=1400x_.jpeg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/eeb840595ea141f98c52c661688def63_s=860x_.jpeg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":7}}>
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/5343a0ac0fff405783805bb2014d0637_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36990/5343a0ac0fff405783805bb2014d0637_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36990/5343a0ac0fff405783805bb2014d0637_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36990/5343a0ac0fff405783805bb2014d0637_s=1400x_.jpeg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/5343a0ac0fff405783805bb2014d0637_s=860x_.jpeg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":20}}>
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/775241d18f4d462898e0234a98dcaba9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/775241d18f4d462898e0234a98dcaba9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/775241d18f4d462898e0234a98dcaba9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36990/775241d18f4d462898e0234a98dcaba9_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/775241d18f4d462898e0234a98dcaba9_s=860x_.jpg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column className="pl--40 pr--40" style={{"paddingTop":6,"paddingBottom":0}} name={"jptun3r9mmk"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper style={{"paddingBottom":7}}>
              
              <Subtitle className="subtitle-box fs--30" content={"Kde nás najdete<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2 pr--40" style={{"backgroundColor":"rgba(0,0,0,1)","paddingLeft":0}} name={"y9o1an5gfge"}>
          
          <FullmapWrap className="--full" style={{"maxWidth":660}}>
            
            <FullmapCover style={{"marginTop":0,"paddingTop":0,"paddingLeft":0,"marginBottom":0,"paddingRight":0}} zoom={"18"} place={"Studio Petra Nováková"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="--center pl--40 pr--40" style={{"paddingTop":24,"backgroundColor":"rgba(0, 0, 0, 1)","paddingBottom":20}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--40 pr--40 flex--bottom" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 btn-box--shadow3 btn-box--center" innerClassName="" content={"CHCI SE OBJEDNAT"} url={"https://studio-loreal-web.mystoodio.app/cms/reservations/business/1/services"} href={"https://studio-loreal-web.mystoodio.app/cms/reservations/business/1/services"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 pl--40 pr--40 flex--bottom" style={{"marginTop":0}} columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--right">
              
              <Title className="title-box fs--36" content={"Adresa<br>"}>
              </Title>

              <Text className="text-box" content={"STUDIO Petra Nováková<br>Vladislavova 332, Kutná Hora\n<br>Česká Republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"paddingLeft":0,"maxWidth":187}}>
              
              <Image style={{"maxWidth":136}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/36990/3d48c0c4abe9456d96bee48b51f9426c_s=350x_.jpg 350w"} alt={""} src={"https://cdn.swbpg.com/t/36990/3d48c0c4abe9456d96bee48b51f9426c_s=350x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"paddingRight":0,"maxWidth":181,"paddingLeft":0}}>
              
              <Image style={{"maxWidth":139}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/36990/a940cf41616d4a65980f2cd2fa73b9e1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/a940cf41616d4a65980f2cd2fa73b9e1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/a940cf41616d4a65980f2cd2fa73b9e1_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/36990/a940cf41616d4a65980f2cd2fa73b9e1_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--36" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box" content={"+420 605 338 117<br>info@studioloreal.cz<br>www.studioloreal.cz&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":144}} alt={""} src={"https://cdn.swbpg.com/t/36990/fc46577831324ec1a5bd8fab214ef308_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/fc46577831324ec1a5bd8fab214ef308_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/fc46577831324ec1a5bd8fab214ef308_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"mb6aghp9h3"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}